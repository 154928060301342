<template>
  <!--입력창 s-->
  <section id="bo_w">
    <p class="bo_w_p_top"><span class="bo_w_red">*</span>표시 항목은 필수입력 항목입니다.</p>
    <div class="write_divbox">
      <div class="bo_w_info write_div line">
        <label for="wr_product">제품<span class="bo_w_red">*</span></label>
        <select name="report_product" id="wr_product" class="form-control" v-model="productId">
          <option disabled selected value="">제품을 선택하세요.</option>
          <option
              v-for="(product,index) in $store.state.menu.productMenu"
              :key="index"
              :value="product.key"
              v-html="`${product.name}(${product.ingredientName})`">
          </option>
        </select>
      </div>
      <div class="bo_w_info write_div line">
        <label for="wr_category">종류</label>
        <select name="request_category" id="wr_category" class="form-control" v-model="category">
          <option value="">종류을 선택하세요.</option>
          <option value="성분">성분</option>
          <option value="적응증">적응증</option>
          <option value="용법/용량">용법/용량</option>
          <option value="이상사례/상호작용/안전성">이상사례/상호작용/안전성</option>
          <option value="성상/제형/포장">성상/제형/포장</option>
          <option value="약물동력학/약물동태학/약리학">약물동력학/약물동태학/약리학</option>
          <option value="공급/유통/약가/보험">공급/유통/약가/보험</option>
          <option value="상기 외 기타">상기 외 기타</option>
        </select>
      </div>

      <div class="bo_w_info write_div line">
        <label for="wr_content">내용<span class="bo_w_red">*</span></label>
        <textarea name="request_content" id="wr_content" rows="10" placeholder="내용을 입력하세요."
                  class="form-control" v-model="content" @input="assertMaxChars"></textarea>
      </div>

      <!--      <div class="bo_w_info write_div line">-->
      <!--        <label for="wr_type">제공경로<span class="bo_w_red">*</span></label>-->
      <!--        <select name="request_type" id="wr_type" class="form-control"-->
      <!--                placeholder="원하시는 제공경로를 선택하세요.">-->
      <!--          <option value="">원하시는 제공경로를 선택하세요.</option>-->
      <!--          <option value="직접방문">직접방문</option>-->
      <!--          <option value="전화">전화</option>-->
      <!--          <option value="이메일">이메일</option>-->
      <!--          <option value="화상 미팅">화상 미팅</option>-->
      <!--        </select>-->
      <!--      </div>-->
    </div>
    <p class="bo_w_ps">본 웹사이트를 통해 등록하신 개인정보는 귀하의 동의 내용과 개인정보보호법의 규정에 따라 보호되고 관리됩니다. 등록하신 개인정보는 귀하의 질의내용 확인 및 이에 대한 답변을
      위한 목적으로 처리되며, 그 목적을 달성할 때까지 보관됩니다. 귀하께서는 본 개인정보동의를 거부하실 수 있으며, 이 경우 문의내용 등록 및 이에 대한 회신이 어려울 수 있습니다. 본 정보 요청에 대한 문의
      확인이나 답변은 등록된 E-mail로 전달해 드립니다. <br/> <br/> 상기 접수내용과 관련하여 긴급한 연락이 필요하신 경우, '내용'란에 긴급연락처
      정보를 남겨주시면 해당 연락처로 연락드리도록 하겠습니다. 더불어 본 내용과 관련하여
      저희가 연락드릴 시 발신번호에 '국제전화번호'로 발신번호 표시될 수 있는 점 양해를
      부탁드립니다.</p>
    <p class="bo_w_agree">등록하신 질의내용 중 환자의 질병상태에 관한 정보가 포함된 경우 한국BMS제약(유)은 귀하의 질의내용 확인 및 이에 대한 답변을 위한 목적을 위하여 환자의 민감정보를
      처리합니다.</p>
    <div class="agree_ck">
      <input type="checkbox" name="agree" title="동의합니다" v-model="agreeProcessSensitiveInformation">
      <p>동의합니다</p>
    </div>
    <p class="bo_w_agree">한국BMS제약(유)은 정확한 답변을 위하여 회사가 수집한 위 정보를 해외 BMS 계열사와 공유하며 BMS 계열사는 www.bms.com 에서 확인하실 수
      있습니다.</p>
    <div class="agree_ck">
      <input type="checkbox" name="agree" title="동의합니다" v-model="agreeShareAffiliates">
      <p>동의합니다</p>
    </div>
    <div class="btn_area" @click="sand">
      <button class="btn_main">제출하기</button>
    </div>
    <!-- 의학정보요청 Modal -->
    <div class="modal" v-if="showSubmitModal">

      <!-- 의학정보요청 Modal의 내용 -->
      <div class="modal_contents">
        <div class="modal_close close">
          <a @click="closeModal">close</a>
        </div>
        <p class="modal_tit">선생님의 의학정보 요청 신청이 <br>접수되었습니다.</p>
        <p class="modal_exp">접수된 내용을 바탕으로 빠른 시일 내에<br> 선생님께 연락드리도록 하겠습니다.</p>
        <p class="modal_exp">감사합니다.</p>
        <div class="modal_btn">
          <span class="btn_modal" @click="closeModal">확인</span>
        </div>
      </div>
    </div>
  </section>
  <!--입력창 e-->
</template>

<script>
import sendReportAPI from "@/api/sendReportAPI";

export default {
  name: 'RequestForm',
  data() {
    return {
      productId: '',
      category: '',
      content: '',
      agreeProcessSensitiveInformation: true,
      agreeShareAffiliates: true,
      maxLengthInCars: 2000,
      showSubmitModal: false,
    }
  },
  mounted() {
    if (this.$route.query['open-modal']) {
      this.$nextTick(() => {
        this.switchModal()
      })
    }
  },
  methods: {
    switchModal() {
      this.showSubmitModal = !this.showSubmitModal;
      if (this.showSubmitModal) {
        document.documentElement.classList.add("overflow-y-hidden");
      } else {
        document.documentElement.classList.remove("overflow-y-hidden");
      }
    },
    validate() {
      if ((!this.productId && !this.category && !this.content && !this.agreeProcessSensitiveInformation && !this.agreeShareAffiliates)
          || (!this.productId && !this.category)
          || (!this.productId && !this.content)
          || (!this.productId && !this.agreeProcessSensitiveInformation)
          || (!this.productId && !this.agreeShareAffiliates)
          || (!this.category && !this.content)
          || (!this.category && !this.agreeProcessSensitiveInformation)
          || (!this.category && !this.agreeShareAffiliates)
          || (!this.content && !this.agreeProcessSensitiveInformation)
          || (!this.content && !this.agreeShareAffiliates)
          || (!this.agreeProcessSensitiveInformation && !this.agreeShareAffiliates)
      ) {
        alert('제품, 내용, 종류, 민감정보제공동의, 계열사정보공 유동의는 필수 입력 항목입니다. 입력하지 않은 항목이 있는지 확인해주세요.')
        return false
      }
      if (!this.productId) {
        alert('제품을 선택해주세요.')
        return false;
      }
      if (!this.category) {
        alert('종류를 선택해주세요.')
        return false;
      }
      if (!this.content) {
        alert('내용을 입력해주세요.')
        return false;
      }
      if (!this.agreeProcessSensitiveInformation) {
        alert('민감정보제공 동의에 반드시 동의하셔야만 의학정보요청을 완료하실 수 있습니다.')
        return false;
      }
      if (!this.agreeShareAffiliates) {
        alert('계열사정보공유 동의에 반드시 동의하셔야 만 의학정보요청을 완료하실 수 있습니다.')
        return false;
      }
      if (this.content.length <= 10) {
        alert('의학정보요청 내용을 정확히 이해할 수 있도 록 10자 이상의 내용을 입력해주세요.')
        return false;
      }
      if (/<\/?\w+((\s+\w+(\s*=\s*(?:".*?"|'.*?'|[^'">\s]+))?)+\s*|\s*)\/?>/.test(this.content)) {
        alert('내용 란에는 한글, 영문, 숫자, 특수문자 외에 입력이 허용되지 않습니다. 입력하신 내용을 확인해주세요.')
        return false;
      }
      return true;
    },
    closeModal() {
      this.switchModal()
      this.productId =  ''
      this.category =  ''
      this.content =  ''
      this.agreeProcessSensitiveInformation = true
      this.agreeShareAffiliates = true
      this.maxLengthInCars = 2000
      this.showSubmitModal = false
    },
    async sand() {
      if (this.validate()) {
        await sendReportAPI.postMedicalRequest({
          productId: this.productId,
          category: this.category,
          content: this.content,
          agreeProcessSensitiveInformation: this.agreeProcessSensitiveInformation,
          agreeShareAffiliates: this.agreeShareAffiliates
        }).then(value => {
          const {data} = value
          if (data.code === 200) {
            this.switchModal()
          }
        }).catch(e => {
          console.log(e)
          throw e
        })
      }
    },
    assertMaxChars: function () {
      if (this.content.length >= this.maxLengthInCars) {
        alert('내용을 한글 2,000자 이내로 입력해주세요.')
        this.content = this.content.substring(0, this.maxLengthInCars);
      }
    }
  },
};
</script>

<style scoped>

</style>
