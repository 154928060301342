<template>
  <!--상단 타이틀 영역 s-->
  <div :class="['top-level-page-area', `top-level-page-area-${type}`]">
    <div class="bms-relaunch-comp-v2">
      <slot>
        <h1 class="page-header" v-text="header"/>
        <div class="feature-body">
          <p v-text="body"/>
        </div>
      </slot>
    </div>
  </div>
  <!--상단 타이틀 영역 e-->
</template>

<script>
export default {
  name: "TopTitle",
  props: {
    type: {
      type: String,
      required: true,
    },
    header: {
      type: String,
    },
    body: {
      type: String,
    },
  },
}
</script>

<style scoped>

</style>
