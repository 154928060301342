<template>
  <main class="site-main">

    <!--상단 타이틀 영역 s-->
    <top-title
      type="05"
      header="의학정보요청 및 이상사례보고"
      body="BMS 제품 관련 의학정보를 요청하시거나 이상사례 보고를 위한 신청서비스입니다."
    />
    <!--상단 타이틀 영역 e-->

    <div class="container">

      <!--Breadcrumb 영역 s-->
      <div class="top-nav-container">
        <nav role="navigator">
          <ul class="breadcrumbs list-unstyled hide-sm">
            <li class="breadcrumb">의학정보요청 및 이상사례보고</li>
          </ul>
        </nav>
      </div>
      <!--Breadcrumb 영역 e-->

      <div class="section">
        <!--탭메뉴 s-->
        <div class="lsheading">
          <div class="lsheading-content">
            <div class="pod-actions lsheading-action">
              <div class="lsheading-nav">
                <a title="의학정보요청" aria-label="의학정보요청" target=""
                   @click="tab = 'request'"
                   :class="['lsanchor',{'active': tab==='request'}]">의학정보요청</a>
                <div class="ruler"></div>
                <a title="이상사례보고" aria-label="이상사례보고" target=""
                   @click="tab = 'report'"
                   :class="['lsanchor',{'active': tab==='report'}]">이상사례보고</a>
              </div>
            </div>
          </div>
        </div>
        <!--탭메뉴 e-->

        <!--입력창 s-->
        <request-form v-if="tab === 'request'"/>
        <report-form v-else/>
        <!--입력창 e-->
      </div>
    </div>
  </main>
</template>

<script>

import ReportForm from '@/components/reportForm/report';
import RequestForm from '@/components/reportForm/request';
import TopTitle from "@/components/common/TopTitle";


export default {
  name: 'RequestReport',
  components: {
    TopTitle,
    ReportForm,
    RequestForm,
  },
  data() {
    return {
      tab: this.$route.query['select-tab']??'request',
    };
  },
};
</script>

<style scoped>

</style>
