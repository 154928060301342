<template>
  <section id="bo_w">
    <p class="bo_w_p_exp">의약품을 투여 받은 환자 또는 임상시험 대상자에게 발생한 일체의 부정적인 의학적 사례이며 해당 치료와 인과관계가 반드시
      있어야 할 필요는 없습니다. 따라서,
      의약품과의 관련 여부와 무관하게, 이상사례 (AE)는 의약품 사용과의 시간 선후상 관련이 있는 바람직하지 않고 의도하지 않은 모든 징후 (예를 들어, 실험실
      검사 이상 소견 포함), 증상 또는 질병이 될 수 있습니다. 이상사례 외의 안전성 정보가 있을 경우에도 작성 부탁드립니다. 이상사례 외 보고가 필요한 안전성
      정보의 정의는
      <button type='button' @click="showModal" class="modal_link">[여기]</button>
      를 참고해주십시오.
    </p>

    <div class="black_bg" v-show="openModal"></div>
    <div class="modal_wrap" v-show="openModal">
      <div class="modal_close">
        <a role="link" @click="showModal">close</a>
      </div>
      <p>이상사례 외의 안전성 정보</p>
      <ul>
        <li>과량 복용</li>
        <li>유효성 부족</li>
        <li>남용</li>
        <li>오용</li>
        <li>허가사항 외 사용</li>
        <li>직업상 노출</li>
        <li>투약 과오 및 잠재적인 투약 과오</li>
        <li>의도하지 않은 이점</li>
        <li>사건과 관련성이 있거나 없이 의심되는 감염원 전파 (예: 의약품을 통한 일체의 미생물, 바이러스 또는 병원성 또는 비병원성 감염성 입자)
        </li>
        <li>임신 관련 정보</li>
      </ul>
      <div class="modal_close2" role="link" @click="showModal">
        <a>확 인</a>
      </div>
    </div>

    <p class="bo_w_p_top"><span class="bo_w_red">*</span>표시 항목은 필수입력 항목입니다.</p>
    <div class="write_divbox">
      <div class="bo_w_info write_div line">
        <label for="wr_product">제품<span class="bo_w_red">*</span></label>
        <select name="report_product" id="wr_product" class="form-control" v-model="productId">
          <option disabled selected value="">제품을 선택하세요.</option>
          <option
              v-for="(product,index) in $store.state.menu.productMenu"
              :key="index"
              :value="product.key"
              v-html="`${product.name}(${product.ingredientName})`">
          </option>
        </select>
      </div>

      <div class="bo_w_info write_div line">
        <label for="wr_patient">환자정보<span class="bo_w_red">*</span></label>
        <textarea name="info_patient" id="wr_patient" rows="10"
                  placeholder="환자 initial, 성별, 나이 (또는 생년월일) 등의 환자 정보를 기입해주세요."
                  class="form-control" v-model="patientInformation" @input="patientInformationAssertMaxChars('환자정보',2000)"></textarea>
      </div>

      <div class="bo_w_info write_div line">
        <label for="wr_case">이상사례<span class="bo_w_red">*</span></label>
        <textarea name="case_content" id="wr_case" rows="10"
                  placeholder="의약품을 투여 받은 환자 또는 임상시험 대상자에게 발생한 일체의 부정적인 의학적 사례이며 해당 치료와 인과관계가 반드시 있어야 할 필요는 없습니다. 따라서, 의약품과의 관련 여부와 무관하게, 이상사례 (AE)는 의약품 사용과의 시간 선후상 관련이 있는 바람직하지 않고 의도하지 않은 모든 징후 (예를 들어, 실험실 검사 이상 소견 포함),  증상 또는 질병이 될 수 있습니다. 이상사례 외의 안전성 정보가 있을 경우에도 작성 부탁드립니다."
                  class="form-control" v-model="abnormalCase" @input="abnormalCaseAssertMaxChars('이상사례',5000)"></textarea>
      </div>

    </div>
    <p class="bo_w_ps">본 웹사이트를 통해 등록하신 개인정보는 귀하의 동의 내용과 개인정보보호법의 규정에 따라 보호되고 관리됩니다. 등록하신
      개인정보는
      귀하의 질의내용 확인 및 이에
      대한 답변을 위한 목적으로 처리되며, 그 목적을 달성할 때까지 보관됩니다. 귀하께서는 본 개인정보동의를 거부하실 수 있으며, 이 경우 문의내용
      등록 및 이에
      대한 회신이 어려울 수 있습니다.<br/><br/> 상기 접수내용과 관련하여 긴급한 연락이 필요하신 경우, '이상사례'란에 긴급연락처
      정보를 남겨주시면 해당 연락처로 연락드리도록 하겠습니다. 더불어 본 내용과 관련하여
      저희가 연락드릴 시 발신번호에 '국제전화번호'로 발신번호 표시될 수 있는 점 양해를
      부탁드립니다.</p>

    <p class="bo_w_agree">등록하신 질의내용 중 환자의 질병상태에 관한 정보가 포함된 경우 한국BMS제약(유)은 귀하의 질의내용 확인 및
      이에 대한
      답변을 위한 목적을 위하여 환자의 민감정보를 처리합니다.</p>
    <div class="agree_ck">
      <input type="checkbox" name="agree" title="동의합니다" v-model="agreeProcessSensitiveInformation">
      <p>동의합니다</p>
    </div>

    <p class="bo_w_agree">한국BMS제약(유)은 정확한 답변을 위하여 회사가 수집한 위 정보를 해외 BMS 계열사와 공유하며 BMS
      계열사는
      www.bms.com 에서 확인하실 수 있습니다.</p>
    <div class="agree_ck">
      <input type="checkbox" name="agree" title="동의합니다" v-model="agreeShareAffiliates">
      <p>동의합니다</p>
    </div>

    <p class="bo_w_agree">보고하신 사례와 관련하여 한국BMS제약 의약품안전성정보팀에서 좀 더 자세한 정보를 얻고자 연락드릴 수 있습니다.
      한국BMS제약
      의약품안전성정보팀에서 연락드리는 것에 동의합니다.</p>
    <div class="agree_ck">
      <input type="radio" name="agree_radio" :value="true" v-model="agreeShareContacts">
      <p>동의합니다</p>
      <input type="radio" name="agree_radio" :value="false" v-model="agreeShareContacts">
      <p>동의하지 않습니다</p>
    </div>

    <div class="btn_area" @click="sand">
      <button class="btn_main">제출하기</button>
    </div>

    <div class="modal" v-show="showSubmitModal">
      <!-- 직원방문요청 Modal의 내용 -->
      <div class="modal_contents">
        <div class="modal_close close">
          <a @click="closeModal">close</a>
        </div>
        <p class="modal_tit">선생님의 이상사례보고<br>신청이 접수되었습니다.</p>
        <p class="modal_exp">접수된 내용을 검토 후 빠른 시일 내에 선생님께 회신 드리도록 하겠습니다.<br><br>
          감사합니다.</p>
        <div class="modal_btn">
          <span class="btn_modal" @click="closeModal">확인</span>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import sendReportAPI from "@/api/sendReportAPI";

export default {
  name: 'ReportForm',
  data() {
    return {
      productId: '',
      patientInformation: '',
      abnormalCase: '',
      agreeProcessSensitiveInformation: true,
      agreeShareAffiliates: true,
      agreeShareContacts: true,

      openModal: false,
      showSubmitModal: false,
    }
  },
  mounted() {
    if(this.$route.query['open-modal']) {
      this.$nextTick(() => {
        this.switchModal()
      })
    }
  },
  methods: {
    switchModal() {
      this.showSubmitModal = !this.showSubmitModal;
      if (this.showSubmitModal) {
        document.documentElement.classList.add("overflow-y-hidden");
      } else {
        document.documentElement.classList.remove("overflow-y-hidden");
      }
    },
    validate() {
      if ((!this.productId && !this.patientInformation && !this.abnormalCase && !this.agreeProcessSensitiveInformation && !this.agreeShareAffiliates && !this.agreeShareContacts)
          || (!this.productId && !this.patientInformation)
          || (!this.productId && !this.abnormalCase)
          || (!this.productId && !this.agreeProcessSensitiveInformation)
          || (!this.productId && !this.agreeShareAffiliates)
          || (!this.productId && !this.agreeShareContacts)
          || (!this.patientInformation && !this.abnormalCase)
          || (!this.patientInformation && !this.agreeProcessSensitiveInformation)
          || (!this.patientInformation && !this.agreeShareAffiliates)
          || (!this.patientInformation && !this.agreeShareContacts)
          || (!this.agreeProcessSensitiveInformation && !this.agreeShareAffiliates)
          || (!this.agreeProcessSensitiveInformation && !this.agreeShareContacts)
          || (!this.agreeShareAffiliates && !this.agreeShareContacts)
      ) {
        alert('제품, 환자정보, 이상사례, 민감정보제공동의, 계열사정보 공유동의, 연락수신동의는 필수 입력 항목입니다. 입력하 지 않은 항목이 있는지 확인해주세요.')
        return false
      }
      if (!this.productId) {
        alert('제품을 선택해주세요.')
        return false;
      }
      if (!this.patientInformation) {
        alert('환자정보는 필수입력 항목입니다. 내용을 입력해주세요.')
        return false;
      }
      if (!this.abnormalCase) {
        alert('이상사례는 필수입력 항목입니다. 내용을 입력해주세요.')
        return false;
      }
      if (!this.agreeProcessSensitiveInformation) {
        alert('민감정보제공 동의에 반드시 동의하셔야만 이상사례보고 를 완료하실 수 있습니다.')
        return false;
      }
      if (!this.agreeShareAffiliates) {
        alert('계열사정보공유 동의에 반드시 동의하셔야만 이상사례보 고를 완료하실 수 있습니다.')
        return false;
      }
      if (!this.agreeShareContacts) {
        alert('연락 수신 동의에 반드시 동의 하셔야만 이상사례보고를 완료하실 수 있습니다.')
        return false;
      }
      if (/<\/?\w+((\s+\w+(\s*=\s*(?:".*?"|'.*?'|[^'">\s]+))?)+\s*|\s*)\/?>/.test(this.patientInformation)) {
        alert('환자정보 란에는 한글, 영문, 숫자, 특수문자 외에 입력이 허용되지 않습니다. 입력하신 내용을 확인해주세요.')
        return false;
      }
      if (/<\/?\w+((\s+\w+(\s*=\s*(?:".*?"|'.*?'|[^'">\s]+))?)+\s*|\s*)\/?>/.test(this.abnormalCase)) {
        alert('이상사례 란에는 한글, 영문, 숫자, 특수문자 외에 입력이 허용되지 않습니다. 입력하신 내용을 확인해주세요.')
        return false;
      }
      return true;
    },
    patientInformationAssertMaxChars: function (title, maxLength) {
      if (this.patientInformation.length >= maxLength) {
        alert(`${title}를 한글 ${maxLength.toLocaleString()}자 이내로 입력해주세요.`)
        this.patientInformation = this.patientInformation.substring(0, this.maxLength);
      }
    },
    abnormalCaseAssertMaxChars: function (title, maxLength) {
      if (this.abnormalCase.length >= maxLength) {
        alert(`${title}를 한글 ${maxLength.toLocaleString()}자 이내로 입력해주세요.`)
        this.abnormalCase = this.abnormalCase.substring(0, this.maxLength);
      }
    },
    closeModal() {
      this.switchModal()
      this.productId = ''
      this.patientInformation = ''
      this.abnormalCase = ''
      this.agreeProcessSensitiveInformation = true
      this.agreeShareAffiliates = true
      this.agreeShareContacts = true
      this.openModal = false
      this.showSubmitModal = false
    },
    showModal() {
      this.openModal = !this.openModal;
      if (this.openModal) {
        document.documentElement.classList.add("overflow-y-hidden");
      } else {
        document.documentElement.classList.remove("overflow-y-hidden");
      }
    },
    async sand() {
      if (this.validate()) {
        await sendReportAPI.postAbnormalReport({
          productId: this.productId,
          patientInformation: this.patientInformation,
          abnormalCase: this.abnormalCase,
          agreeProcessSensitiveInformation: this.agreeProcessSensitiveInformation,
          agreeShareAffiliates: this.agreeShareAffiliates,
          agreeShareContacts: this.agreeShareContacts
        }).then(value => {
          const {data} = value
          console.log(data)
          if (data.code === 200) {
            this.switchModal()
          }
        }).catch(e => {
          console.log(e)
          throw e
        })
      }
    },
  }
};
</script>

<style scoped>

</style>
